var data = [
    {"translation": {"en": "Afghanistan", "tr": "Afganistan"}, "code": "0093"},
    {"translation": {"en": "Germany", "tr": "Almanya"}, "code": "0049"},
    {"translation": {"en": "United States of America", "tr": "Amerika Birleşik Devletleri"}, "code": "001"},
    {"translation": {"en": "American Samoa", "tr": "Amerikan Samoa"}, "code": "00684"},
    {"translation": {"en": "Andorra", "tr": "Andorra"}, "code": "00376"},
    {"translation": {"en": "Angola", "tr": "Angola"}, "code": "00244"},
    {"translation": {"en": "Anguilla", "tr": "Anguilla"}, "code": "001264"},
    {"translation": {"en": "Antigua", "tr": "Antigua"}, "code": "001268"},
    {"translation": {"en": "Argentina", "tr": "Arjantin"}, "code": "0054"},
    {"translation": {"en": "Albania", "tr": "Arnavutluk"}, "code": "00355"},
    {"translation": {"en": "Aruba", "tr": "Aruba"}, "code": "00297"},
    {"translation": {"en": "Ascension", "tr": "Ascension"}, "code": "00247"},
    {"translation": {"en": "Australia", "tr": "Avustralya"}, "code": "0061"},
    {"translation": {"en": "Austria", "tr": "Avusturya"}, "code": "0043"},
    {"translation": {"en": "Azerbaijan", "tr": "Azerbaycan"}, "code": "00994"},
    {"translation": {"en": "Azores–Mader Islands", "tr": "Azor – Mader Adaları"}, "code": "00351"},
    {"translation": {"en": "Bahama", "tr": "Bahama"}, "code": "001242"},
    {"translation": {"en": "bahrain", "tr": "Bahreyn"}, "code": "00973"},
    {"translation": {"en": "Bangladesh", "tr": "Bangladeş"}, "code": "00880"},
    {"translation": {"en": "Barbados", "tr": "Barbados"}, "code": "001246"},
    {"translation": {"en": "Western Samoa", "tr": "Batı Samoa"}, "code": "00685"},
    {"translation": {"en": "Belize", "tr": "Belize"}, "code": "00501"},
    {"translation": {"en": "Belgium", "tr": "Belçika"}, "code": "0032"},
    {"translation": {"en": "Benin", "tr": "Benin"}, "code": "00229"},
    {"translation": {"en": "Bermuda", "tr": "Bermuda"}, "code": "001441"},
    {"translation": {"en": "Belarus", "tr": "Beyaz Rusya"}, "code": "00375"},
    {"translation": {"en": "Bhutan", "tr": "Bhutan"}, "code": "00975"},
    {"translation": {"en": "United Arab Emirates", "tr": "Birleşik Arap Emirlikleri"}, "code": "00971"},
    {"translation": {"en": "Burma", "tr": "Birmanya"}, "code": "0095"},
    {"translation": {"en": "Bolivia", "tr": "Bolivya"}, "code": "00591"},
    {"translation": {"en": "Bosnia and Herzegovina", "tr": "Bosna Hersek"}, "code": "00387"},
    {"translation": {"en": "botswana", "tr": "Botsvana"}, "code": "00267"},
    {"translation": {"en": "Brazil", "tr": "Brezilya"}, "code": "0055"},
    {"translation": {"en": "British Virgin Adl.", "tr": "British Virgin Adl."}, "code": "001284"},
    {"translation": {"en": "Brunei", "tr": "Brunei"}, "code": "00673"},
    {"translation": {"en": "Bulgaria", "tr": "Bulgaristan"}, "code": "00359"},
    {"translation": {"en": "Burkina Faso", "tr": "Burkina Faso"}, "code": "00226"},
    {"translation": {"en": "Burma", "tr": "Burma"}, "code": "0095"},
    {"translation": {"en": "Burundi", "tr": "Burundi"}, "code": "00257"},
    {"translation": {"en": "Cayman Islands", "tr": "Cayman Adaları"}, "code": "001345"},
    {"translation": {"en": "Gibraltar", "tr": "Cebelitarık"}, "code": "00350"},
    {"translation": {"en": "Algeria", "tr": "Cezayir"}, "code": "00213"},
    {"translation": {"en": "Djibouti", "tr": "Cibuti"}, "code": "00253"},
    {"translation": {"en": "Cook Island", "tr": "Cook Adası"}, "code": "00682"},
    {"translation": {"en": "Denmark", "tr": "Danimarka"}, "code": "0045"},
    {"translation": {"en": "Diego Garsia", "tr": "Diego Garsia"}, "code": "00246"},
    {"translation": {"en": "Dominican Republic", "tr": "Dominik Cumhuriyeti"}, "code": "001809"},
    {"translation": {"en": "dominican", "tr": "Dominik"}, "code": "001767"},
    {"translation": {"en": "Equatorial Guinea", "tr": "Ekvator Ginesi"}, "code": "00240"},
    {"translation": {"en": "Equator", "tr": "Ekvator"}, "code": "00593"},
    {"translation": {"en": "El Salvador", "tr": "El Salvador"}, "code": "00503"},
    {"translation": {"en": "Indonesia", "tr": "Endonezya"}, "code": "0062"},
    {"translation": {"en": "Armenia", "tr": "Ermenistan"}, "code": "00374"},
    {"translation": {"en": "Eritrea", "tr": "Erıtre"}, "code": "00291"},
    {"translation": {"en": "Estonia", "tr": "Estonya"}, "code": "00372"},
    {"translation": {"en": "Ethiopia", "tr": "Etiyopya"}, "code": "00251"},
    {"translation": {"en": "Falkland Name.", "tr": "Falkland Adl."}, "code": "00500"},
    {"translation": {"en": "Faroe Islands", "tr": "Faroe Adaları"}, "code": "00298"},
    {"translation": {"en": "Morocco", "tr": "Fas"}, "code": "00212"},
    {"translation": {"en": "Ivory Coast", "tr": "Fildişi Sahili"}, "code": "00225"},
    {"translation": {"en": "Philippines", "tr": "Filipinler"}, "code": "0063"},
    {"translation": {"en": "Finland", "tr": "Finlandiya"}, "code": "00358"},
    {"translation": {"en": "France", "tr": "Fransa"}, "code": "0033"},
    {"translation": {"en": "French Guiana", "tr": "Fransız Guyanası"}, "code": "00594"},
    {"translation": {"en": "French Polynesia", "tr": "Fransız Polenezyası"}, "code": "00689"},
    {"translation": {"en": "Gabon", "tr": "Gabon"}, "code": "00241"},
    {"translation": {"en": "Gambia", "tr": "Gambia"}, "code": "00220"},
    {"translation": {"en": "Ghana", "tr": "Gana"}, "code": "00233"},
    {"translation": {"en": "Guinea Bissau", "tr": "Gine Bissau"}, "code": "00245"},
    {"translation": {"en": "Guinea", "tr": "Gine"}, "code": "00224"},
    {"translation": {"en": "Grenada", "tr": "Grenada"}, "code": "001473"},
    {"translation": {"en": "Greenland", "tr": "Grönland"}, "code": "00299"},
    {"translation": {"en": "guadalup", "tr": "Guadalup"}, "code": "00590"},
    {"translation": {"en": "guam", "tr": "Guam"}, "code": "001671"},
    {"translation": {"en": "Guatemala", "tr": "Guatemala"}, "code": "00502"},
    {"translation": {"en": "Guyana", "tr": "Guyana"}, "code": "00592"},
    {"translation": {"en": "South Africa Rep.", "tr": "Güney Afrika Cum."}, "code": "0027"},
    {"translation": {"en": "Georgia", "tr": "Gürcistan"}, "code": "00995"},
    {"translation": {"en": "Haiti", "tr": "Haiti"}, "code": "00509"},
    {"translation": {"en": "India", "tr": "Hindistan"}, "code": "0091"},
    {"translation": {"en": "Netherlands Antilles", "tr": "Hollanda Antilleri"}, "code": "00599"},
    {"translation": {"en": "Holland", "tr": "Hollanda"}, "code": "0031"},
    {"translation": {"en": "Honduras", "tr": "Honduras"}, "code": "00504"},
    {"translation": {"en": "Hong Kong", "tr": "Hong Kong"}, "code": "00852"},
    {"translation": {"en": "Croatia", "tr": "Hırvatistan"}, "code": "00385"},
    {"translation": {"en": "Iraq", "tr": "Irak"}, "code": "00964"},
    {"translation": {"en": "eng. Virgin Adl.", "tr": "İng. Virjin Adl."}, "code": "001"},
    {"translation": {"en": "England", "tr": "İngiltere"}, "code": "0044"},
    {"translation": {"en": "Iranian", "tr": "İran"}, "code": "0098"},
    {"translation": {"en": "Ireland", "tr": "İrlanda"}, "code": "00353"},
    {"translation": {"en": "Spain", "tr": "İspanya"}, "code": "0034"},
    {"translation": {"en": "Israel", "tr": "İsrail"}, "code": "00972"},
    {"translation": {"en": "Sweden", "tr": "İsveç"}, "code": "0046"},
    {"translation": {"en": "Switzerland", "tr": "İsviçre"}, "code": "0041"},
    {"translation": {"en": "Italy", "tr": "İtalya"}, "code": "0039"},
    {"translation": {"en": "Iceland", "tr": "İzlanda"}, "code": "00354"},
    {"translation": {"en": "Jamaica", "tr": "Jamaika"}, "code": "001876"},
    {"translation": {"en": "Japan", "tr": "Japonya"}, "code": "0081"},
    {"translation": {"en": "TRNC", "tr": "K.K.T.C."}, "code": "00392"},
    {"translation": {"en": "Cambodia", "tr": "Kamboçya"}, "code": "00855"},
    {"translation": {"en": "Cameroon", "tr": "Kamerun"}, "code": "00237"},
    {"translation": {"en": "Canada", "tr": "Kanada"}, "code": "001"},
    {"translation": {"en": "Kap Verd.", "tr": "Kap Verd."}, "code": "00238"},
    {"translation": {"en": "Train", "tr": "Katar"}, "code": "00974"},
    {"translation": {"en": "Kazakhistan", "tr": "Kazakistan"}, "code": "007"},
    {"translation": {"en": "Kenya", "tr": "Kenya"}, "code": "00254"},
    {"translation": {"en": "Kiribati", "tr": "Kiribati"}, "code": "00686"},
    {"translation": {"en": "Colombia", "tr": "Kolombiya"}, "code": "0057"},
    {"translation": {"en": "Comoros", "tr": "Komor Adaları"}, "code": "00269"},
    {"translation": {"en": "Congo People's Rep.", "tr": "Kongo Halk Cum."}, "code": "00242"},
    {"translation": {"en": "Korean Rep. South", "tr": "Kore Cum. Güney"}, "code": "0082"},
    {"translation": {"en": "Costa Rica", "tr": "Kosta Rika"}, "code": "00506"},
    {"translation": {"en": "Kuwait", "tr": "Kuveyt"}, "code": "00965"},
    {"translation": {"en": "North Korea", "tr": "Kuzey Kore"}, "code": "00850"},
    {"translation": {"en": "Cuba", "tr": "Küba"}, "code": "0053"},
    {"translation": {"en": "Greek Cypriot Rep.", "tr": "Kıbrıs Rum Cum."}, "code": "00357"},
    {"translation": {"en": "Kyrgyzstan", "tr": "Kırgızistan"}, "code": "00996"},
    {"translation": {"en": "Lao Dem. People's Rep.", "tr": "Lao Dem. Halk Cum."}, "code": "00856"},
    {"translation": {"en": "Lesotho", "tr": "Lesotho"}, "code": "00266"},
    {"translation": {"en": "Latvia", "tr": "Letonya"}, "code": "00371"},
    {"translation": {"en": "Liberia", "tr": "Liberya"}, "code": "00231"},
    {"translation": {"en": "Libya", "tr": "Libya"}, "code": "00218"},
    {"translation": {"en": "Liechtenstein", "tr": "Lihtenstayn"}, "code": "0041"},
    {"translation": {"en": "Lithuania", "tr": "Litvanya"}, "code": "00370"},
    {"translation": {"en": "Lebanon", "tr": "Lübnan"}, "code": "00961"},
    {"translation": {"en": "Luxembourg", "tr": "Lüksemburg"}, "code": "00352"},
    {"translation": {"en": "Hungary", "tr": "Macaristan"}, "code": "0036"},
    {"translation": {"en": "Madagascar", "tr": "Madagaskar"}, "code": "00261"},
    {"translation": {"en": "Macau", "tr": "Makao"}, "code": "00853"},
    {"translation": {"en": "Macedonia", "tr": "Makedonya"}, "code": "00389"},
    {"translation": {"en": "Malawi", "tr": "Malavi"}, "code": "00265"},
    {"translation": {"en": "Maldive Islands", "tr": "Maldiv Adaları"}, "code": "00960"},
    {"translation": {"en": "Malaysia", "tr": "Malezya"}, "code": "0060"},
    {"translation": {"en": "Financial", "tr": "Mali"}, "code": "00223"},
    {"translation": {"en": "Malsar Islands", "tr": "Malsar Adaları"}, "code": "00692"},
    {"translation": {"en": "Malta", "tr": "Malta"}, "code": "00356"},
    {"translation": {"en": "Marian Islands", "tr": "Marian Adaları"}, "code": "001670"},
    {"translation": {"en": "Martinique", "tr": "Martinik"}, "code": "00596"},
    {"translation": {"en": "Mexican", "tr": "Meksika"}, "code": "0052"},
    {"translation": {"en": "Central African Rep.", "tr": "Merkezi Afrika Cum."}, "code": "00236"},
    {"translation": {"en": "micronesia", "tr": "Mikronezya"}, "code": "00691"},
    {"translation": {"en": "moldova", "tr": "Moldovya"}, "code": "00373"},
    {"translation": {"en": "Monaco", "tr": "Monaco"}, "code": "00377"},
    {"translation": {"en": "montserrat", "tr": "Montserrat"}, "code": "001664"},
    {"translation": {"en": "Morris Islands", "tr": "Moris Adaları"}, "code": "00230"},
    {"translation": {"en": "Mauritania", "tr": "Moritanya"}, "code": "00222"},
    {"translation": {"en": "mozambique", "tr": "Mozambik"}, "code": "00258"},
    {"translation": {"en": "Mongolia", "tr": "Moğolistan"}, "code": "00976"},
    {"translation": {"en": "Sweetcorn", "tr": "Mısır"}, "code": "0020"},
    {"translation": {"en": "Nakhodka", "tr": "Nakhodka"}, "code": "007504915"},
    {"translation": {"en": "Nakhodka", "tr": "Nakhodka"}, "code": "00750492"},
    {"translation": {"en": "Namibia", "tr": "Namibya"}, "code": "00264"},
    {"translation": {"en": "Nauru Island", "tr": "Nauru Adası"}, "code": "00674"},
    {"translation": {"en": "Nepal", "tr": "Nepal"}, "code": "00977"},
    {"translation": {"en": "Niger", "tr": "Nijer"}, "code": "00227"},
    {"translation": {"en": "Nigeria", "tr": "Nijerya"}, "code": "00234"},
    {"translation": {"en": "Nicaragua", "tr": "Nikaragua"}, "code": "00505"},
    {"translation": {"en": "Niue Islands", "tr": "Niue Adaları"}, "code": "00683"},
    {"translation": {"en": "norfolk islands", "tr": "Norfolk Adaları"}, "code": "00672"},
    {"translation": {"en": "Norway", "tr": "Norveç"}, "code": "0047"},
    {"translation": {"en": "Pakistan", "tr": "Pakistan"}, "code": "0092"},
    {"translation": {"en": "Palau", "tr": "Palau"}, "code": "00680"},
    {"translation": {"en": "Panama", "tr": "Panama"}, "code": "00507"},
    {"translation": {"en": "Papua New Guinea", "tr": "Papua Yeni Gine"}, "code": "00675"},
    {"translation": {"en": "Paraguay", "tr": "Paraguay"}, "code": "00595"},
    {"translation": {"en": "Peru", "tr": "Peru"}, "code": "0051"},
    {"translation": {"en": "Poland", "tr": "Polonya"}, "code": "0048"},
    {"translation": {"en": "Portugal", "tr": "Portekiz"}, "code": "00351"},
    {"translation": {"en": "Puerto Rico", "tr": "Puerto Rico"}, "code": "001787"},
    {"translation": {"en": "Radius", "tr": "Radius"}, "code": "00750996"},
    {"translation": {"en": "Reunion", "tr": "Reunyon"}, "code": "00262"},
    {"translation": {"en": "Romania", "tr": "Romanya"}, "code": "0040"},
    {"translation": {"en": "rwanda", "tr": "Ruanda"}, "code": "00250"},
    {"translation": {"en": "Russian Federation", "tr": "Rusya Federasyonu"}, "code": "007"},
    {"translation": {"en": "Sakhalin", "tr": "Sakhalın"}, "code": "00750440"},
    {"translation": {"en": "Sakhalin", "tr": "Sakhalın"}, "code": "007504416"},
    {"translation": {"en": "Sakhalin", "tr": "Sakhalın"}, "code": "00750443"},
    {"translation": {"en": "San Marino", "tr": "San Marino"}, "code": "00378"},
    {"translation": {"en": "sao tome", "tr": "Sao Tome"}, "code": "00239"},
    {"translation": {"en": "Senegal", "tr": "Senegal"}, "code": "00221"},
    {"translation": {"en": "Seychelles", "tr": "Seychelles"}, "code": "00248"},
    {"translation": {"en": "Sierra Leone", "tr": "Sierra Leone"}, "code": "00232"},
    {"translation": {"en": "Singapore", "tr": "Singapur"}, "code": "0065"},
    {"translation": {"en": "Slovak Rep.", "tr": "Slovak Cum."}, "code": "00421"},
    {"translation": {"en": "Slovenia", "tr": "Slovenya"}, "code": "00386"},
    {"translation": {"en": "Solomon Islands", "tr": "Solomon Adaları"}, "code": "00677"},
    {"translation": {"en": "Somalia", "tr": "Somali"}, "code": "00252"},
    {"translation": {"en": "Sri Lanka", "tr": "Sri Lanka"}, "code": "0094"},
    {"translation": {"en": "St. Lucia", "tr": "St. Lucia"}, "code": "001758"},
    {"translation": {"en": "St. Marten", "tr": "St. Marten"}, "code": "005995"},
    {"translation": {"en": "St. Helena", "tr": "St.Helena"}, "code": "00290"},
    {"translation": {"en": "St. Kitts", "tr": "St.Kitts"}, "code": "001869"},
    {"translation": {"en": "St. Pierre", "tr": "St.Pierre"}, "code": "00508"},
    {"translation": {"en": "St. Vincent", "tr": "St.Vincent"}, "code": "001784"},
    {"translation": {"en": "Sudan", "tr": "Sudan"}, "code": "00249"},
    {"translation": {"en": "Suriname", "tr": "Surinam"}, "code": "00597"},
    {"translation": {"en": "Syria", "tr": "Suriye"}, "code": "00963"},
    {"translation": {"en": "Saudi Arabia", "tr": "Suudi Arabistan"}, "code": "00966"},
    {"translation": {"en": "Swaziland", "tr": "Svaziland"}, "code": "00268"},
    {"translation": {"en": "Serbia", "tr": "Sırbistan"}, "code": "00381"},
    {"translation": {"en": "Tajikistan", "tr": "Tacikistan"}, "code": "007"},
    {"translation": {"en": "Tanzania", "tr": "Tanzanya"}, "code": "00255"},
    {"translation": {"en": "Tatarstan", "tr": "Tataristan"}, "code": "007843"},
    {"translation": {"en": "Tatıncom", "tr": "Tatıncom"}, "code": "007513"},
    {"translation": {"en": "Thailand", "tr": "Tayland"}, "code": "0066"},
    {"translation": {"en": "Taiwan", "tr": "Tayvan"}, "code": "00886"},
    {"translation": {"en": "Togo", "tr": "Togo"}, "code": "00228"},
    {"translation": {"en": "tokelan", "tr": "Tokelan"}, "code": "00690"},
    {"translation": {"en": "tonga", "tr": "Tonga"}, "code": "00676"},
    {"translation": {"en": "Trinidad & Tobago", "tr": "Trinidad & Tobago"}, "code": "001868"},
    {"translation": {"en": "Tunisia", "tr": "Tunus"}, "code": "00216"},
    {"translation": {"en": "Turks & Caicos Adl.", "tr": "Turks & Caicos Adl."}, "code": "001649"},
    {"translation": {"en": "tuvalu", "tr": "Tuvalu"}, "code": "00688"},
    {"translation": {"en": "Türkiye", "tr": "Türkiye"}, "code": "0090"},
    {"translation": {"en": "Turkmenistan", "tr": "Türkmenistan"}, "code": "00993"},
    {"translation": {"en": "BASE. Virgin Adl.", "tr": "U.S. Virgin Adl."}, "code": "001340"},
    {"translation": {"en": "Uganda", "tr": "Uganda"}, "code": "00256"},
    {"translation": {"en": "Ukraine", "tr": "Ukrayna"}, "code": "00380"},
    {"translation": {"en": "Oman", "tr": "Umman"}, "code": "00968"},
    {"translation": {"en": "uraguay", "tr": "Uraguay"}, "code": "00598"},
    {"translation": {"en": "Vallis Futuna Adl.", "tr": "Vallis Futuna Adl."}, "code": "00681"},
    {"translation": {"en": "Venezuelan", "tr": "Venezuela"}, "code": "0058"},
    {"translation": {"en": "Vietnamese", "tr": "Vietnam"}, "code": "0084"},
    {"translation": {"en": "Yemen Arab Republic", "tr": "Yemen Arap Cumhuriyeti"}, "code": "00967"},
    {"translation": {"en": "People's Republic of Yemen", "tr": "Yemen Halk Cumhuriyeti"}, "code": "00969"},
    {"translation": {"en": "New Hebrites", "tr": "Yeni Hebritler"}, "code": "00678"},
    {"translation": {"en": "New Caledonia", "tr": "Yeni Kaledonya"}, "code": "00687"},
    {"translation": {"en": "New Zealand", "tr": "Yeni Zelenda"}, "code": "0064"},
    {"translation": {"en": "Greece", "tr": "Yunanistan"}, "code": "0030"},
    {"translation": {"en": "Zaire", "tr": "Zaire"}, "code": "00243"},
    {"translation": {"en": "Zambia", "tr": "Zambiya"}, "code": "00260"},
    {"translation": {"en": "Zanzibar", "tr": "Zanzibar"}, "code": "00259"},
    {"translation": {"en": "zimbabwe", "tr": "Zimbabve"}, "code": "00263"},
    {"translation": {"en": "Chad", "tr": "Çad"}, "code": "00235"},
    {"translation": {"en": "Czech Rep.", "tr": "Çek Cum."}, "code": "00420"},
    {"translation": {"en": "Chinese People's Rep.", "tr": "Çin Halk Cum."}, "code": "0086"},
    {"translation": {"en": "Uzbekistan", "tr": "Özbekistan"}, "code": "00998"},
    {"translation": {"en": "Jordan", "tr": "Ürdün"}, "code": "00962"},
    {"translation": {"en": "Chile", "tr": "Şili"}, "code": "0056"},
];
export { data }

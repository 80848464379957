var data = {
    "0": {
        "param_order": [
            "0", 
            "1", 
            "2", 
            "3", 
            "4", 
            "5", 
            "6", 
            "7", 
            "8", 
            "9", 
            "10", 
            "11", 
            "12", 
            "13", 
            "14", 
            "15", 
            "16", 
            "17", 
            "18", 
            "19", 
            "20", 
            "21", 
            "23", 
            "24", 
            "25", 
            "26", 
            "27", 
            "28", 
            "29", 
            "30", 
            "31"
        ], 
        "parameters": {
            "0": {
                "name": {
                    "label": "durum", 
                    "translation": {
                        "en": "status", 
                        "tr": "durum"
                    }, 
                    "value": "0"
                }, 
                "options": [
                    {
                        "label": "pasif", 
                        "translation": {
                            "en": "passive", 
                            "tr": "pasif"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "aktif", 
                        "translation": {
                            "en": "active", 
                            "tr": "aktif"
                        }, 
                        "value": "1"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "1": {
                "name": {
                    "label": "ad", 
                    "translation": {
                        "en": "firstname", 
                        "tr": "ad"
                    }, 
                    "value": "1"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "10": {
                "name": {
                    "label": "mail onay token", 
                    "translation": {
                        "en": "mail approve token", 
                        "tr": "mail onay token"
                    }, 
                    "value": "10"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "11": {
                "name": {
                    "label": "mail", 
                    "translation": {
                        "en": "mail", 
                        "tr": "mail"
                    }, 
                    "value": "11"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "12": {
                "name": {
                    "label": "kapak fotoğrafı", 
                    "translation": {
                        "en": "cover photo", 
                        "tr": "kapak fotoğrafı"
                    }, 
                    "value": "12"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "13": {
                "name": {
                    "label": "aktif dil", 
                    "translation": {
                        "en": "active lang", 
                        "tr": "aktif dil"
                    }, 
                    "value": "13"
                }, 
                "options": [
                    {
                        "label": "tr", 
                        "translation": {
                            "en": "tr", 
                            "tr": "tr"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "en", 
                        "translation": {
                            "en": "en", 
                            "tr": "en"
                        }, 
                        "value": "1"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "14": {
                "name": {
                    "label": "facebook", 
                    "translation": {
                        "en": "facebook", 
                        "tr": "facebook"
                    }, 
                    "value": "14"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "15": {
                "name": {
                    "label": "instagram", 
                    "translation": {
                        "en": "instagram", 
                        "tr": "instagram"
                    }, 
                    "value": "15"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "16": {
                "name": {
                    "label": "linkedin", 
                    "translation": {
                        "en": "linkedin", 
                        "tr": "linkedin"
                    }, 
                    "value": "16"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "17": {
                "name": {
                    "label": "twitter", 
                    "translation": {
                        "en": "twitter", 
                        "tr": "twitter"
                    }, 
                    "value": "17"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "18": {
                "name": {
                    "label": "website", 
                    "translation": {
                        "en": "website", 
                        "tr": "website"
                    }, 
                    "value": "18"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "19": {
                "name": {
                    "label": "youtube", 
                    "translation": {
                        "en": "youtube", 
                        "tr": "youtube"
                    }, 
                    "value": "19"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "2": {
                "name": {
                    "label": "soyad", 
                    "translation": {
                        "en": "lastname", 
                        "tr": "soyad"
                    }, 
                    "value": "2"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "20": {
                "name": {
                    "label": "heşlenmiş şifre", 
                    "translation": {
                        "en": "hashed password", 
                        "tr": "heşlenmiş şifre"
                    }, 
                    "value": "20"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "21": {
                "name": {
                    "label": "hakkında", 
                    "translation": {
                        "en": "about me", 
                        "tr": "hakkında"
                    }, 
                    "value": "21"
                }, 
                "param_order": [
                    "22"
                ], 
                "parameters": {
                    "22": {
                        "name": {
                            "label": "hakkında/not", 
                            "translation": {
                                "en": "note", 
                                "tr": "hakkında/not"
                            }, 
                            "value": "22"
                        }, 
                        "prefix": "", 
                        "show": "yes", 
                        "type": "textarea"
                    }
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "list_parameter"
            }, 
            "23": {
                "name": {
                    "label": "telefon no onay durumu", 
                    "translation": {
                        "en": "phone number approve status", 
                        "tr": "telefon no onay durumu"
                    }, 
                    "value": "23"
                }, 
                "options": [
                    {
                        "label": "hayır", 
                        "translation": {
                            "en": "no", 
                            "tr": "hayır"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "evet", 
                        "translation": {
                            "en": "yes", 
                            "tr": "evet"
                        }, 
                        "value": "1"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "24": {
                "name": {
                    "label": "adres", 
                    "translation": {
                        "en": "address", 
                        "tr": "adres"
                    }, 
                    "value": "24"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "textarea"
            }, 
            "25": {
                "name": {
                    "label": "telefon no", 
                    "translation": {
                        "en": "phone number", 
                        "tr": "telefon no"
                    }, 
                    "value": "25"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "26": {
                "name": {
                    "label": "telefon no onay token", 
                    "translation": {
                        "en": "phone number approve token", 
                        "tr": "telefon no onay token"
                    }, 
                    "value": "26"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "27": {
                "name": {
                    "label": "telefon alan kodu", 
                    "translation": {
                        "en": "phone area code", 
                        "tr": "telefon alan kodu"
                    }, 
                    "value": "27"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "28": {
                "name": {
                    "label": "oluşturma tarihi", 
                    "translation": {
                        "en": "create date", 
                        "tr": "oluşturma tarihi"
                    }, 
                    "value": "28"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "datetime"
            }, 
            "29": {
                "name": {
                    "label": "şifre sıfırlama mail token", 
                    "translation": {
                        "en": "mail password reset token", 
                        "tr": "şifre sıfırlama mail token"
                    }, 
                    "value": "29"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "3": {
                "name": {
                    "label": "kullanıcı adı", 
                    "translation": {
                        "en": "username", 
                        "tr": "kullanıcı adı"
                    }, 
                    "value": "3"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "30": {
                "name": {
                    "label": "şifre sıfırlama tarihi", 
                    "translation": {
                        "en": "password reset date", 
                        "tr": "şifre sıfırlama tarihi"
                    }, 
                    "value": "30"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "31": {
                "name": {
                    "label": "şifre sıfırlama durumu", 
                    "translation": {
                        "en": "password reset status", 
                        "tr": "şifre sıfırlama durumu"
                    }, 
                    "value": "31"
                }, 
                "options": [
                    {
                        "label": "pasif", 
                        "translation": {
                            "en": "passive", 
                            "tr": "pasif"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "aktif", 
                        "translation": {
                            "en": "active", 
                            "tr": "aktif"
                        }, 
                        "value": "1"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "4": {
                "name": {
                    "label": "doğum tarihi", 
                    "translation": {
                        "en": "date of birth", 
                        "tr": "doğum tarihi"
                    }, 
                    "value": "4"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "date"
            }, 
            "5": {
                "name": {
                    "label": "cinsiyet", 
                    "translation": {
                        "en": "gender", 
                        "tr": "cinsiyet"
                    }, 
                    "value": "5"
                }, 
                "options": [
                    {
                        "label": "kadın", 
                        "translation": {
                            "en": "female", 
                            "tr": "kadın"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "erkek", 
                        "translation": {
                            "en": "male", 
                            "tr": "erkek"
                        }, 
                        "value": "1"
                    }, 
                    {
                        "label": "diğer", 
                        "translation": {
                            "en": "other", 
                            "tr": "diğer"
                        }, 
                        "value": "2"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "6": {
                "name": {
                    "label": "eula onay durumu", 
                    "translation": {
                        "en": "eula approve status", 
                        "tr": "eula onay durumu"
                    }, 
                    "value": "6"
                }, 
                "options": [
                    {
                        "label": "hayır", 
                        "translation": {
                            "en": "no", 
                            "tr": "hayır"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "evet", 
                        "translation": {
                            "en": "yes", 
                            "tr": "evet"
                        }, 
                        "value": "1"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "7": {
                "name": {
                    "label": "eula onay tarihi", 
                    "translation": {
                        "en": "eula approve date", 
                        "tr": "eula onay tarihi"
                    }, 
                    "value": "7"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "datetime"
            }, 
            "8": {
                "name": {
                    "label": "eula doküman kodu", 
                    "translation": {
                        "en": "eula document code", 
                        "tr": "eula doküman kodu"
                    }, 
                    "value": "8"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "9": {
                "name": {
                    "label": "mail onay durumu", 
                    "translation": {
                        "en": "mail approve status", 
                        "tr": "mail onay durumu"
                    }, 
                    "value": "9"
                }, 
                "options": [
                    {
                        "label": "hayır", 
                        "translation": {
                            "en": "no", 
                            "tr": "hayır"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "evet", 
                        "translation": {
                            "en": "yes", 
                            "tr": "evet"
                        }, 
                        "value": "1"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }
        }
    }, 
    "about": [], 
    "bmi_gfr": "no", 
    "calculate_eval": "no", 
    "checked_on_open": "no", 
    "date_ability": "yes", 
    "date_ability_limit": "none", 
    "date_ability_type": "date", 
    "date_ability_user_selection": "no", 
    "document_upload": "no", 
    "ending_date_ability": "yes", 
    "ending_date_ability_limit": "none", 
    "ending_date_ability_type": "date", 
    "ending_date_ability_user_selection": "no", 
    "favorite_show_mode": "yes", 
    "language": {
        "label": "Türkçe", 
        "value": "tr"
    }, 
    "model": "wisdom_data", 
    "module": [], 
    "name": {
        "label": "wdm_name !!!", 
        "translation": {
            "en": "user", 
            "tr": "kullanıcı"
        }, 
        "value": "ly + Created Layer Id !!!"
    }, 
    "owner": {
        "label": "katman", 
        "value": "layer"
    }, 
    "owner_type": "layer", 
    "param_group": {
        "param_order": [
            "0"
        ], 
        "parameters": {
            "0": {
                "label": "Param", 
                "value": "0"
            }
        }
    }, 
    "parent": "ly + Created Layer Id", 
    "record_count": "many_times", 
    "status": "published", 
    "style": {
        "caption": {
            "after": "", 
            "backgroundColor": "white", 
            "border": {
                "color": "black", 
                "size": 0, 
                "type": "solid"
            }, 
            "borderRadius": 0, 
            "color": "black", 
            "fontSize": 12
        }, 
        "value": {
            "after": "", 
            "backgroundColor": "white", 
            "border": {
                "color": "black", 
                "size": 0, 
                "type": "solid"
            }, 
            "borderRadius": 0, 
            "color": "black", 
            "fontSize": 10
        }
    }, 
    "value": "ly + Created Layer Id", 
    "version": "1.0"
};
export { data }
